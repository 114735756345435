import {
  ApiError,
  CreateNewBeneficiaryRequest,
  CreateNewBeneficiaryStandaloneRequest,
  GetFormSchemaRequest,
} from "@givenwell/management-api";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { api } from "./utils";

export const formSchemaQuery = (args: GetFormSchemaRequest) =>
  queryOptions({
    queryKey: ["airwallex-form", args],
    queryFn: () => api.airWallex.getFormSchema({ requestBody: args }),
    placeholderData: prev => prev,
  });

export const beneficiaryQuery = (supplierId: string) => {
  return queryOptions({
    queryKey: ["suppliers", supplierId, "beneficiary"],
    queryFn: async () => {
      try {
        const res = await api.airWallex.getBeneficiary({ supplierId });
        return res;
      } catch (e: unknown) {
        if (e instanceof ApiError && e.status === 404) {
          return null;
        }
        throw e;
      }
    },
  });
};

export const formSchemaStandaloneQuery = (country: string, localClearingSystem: string | undefined) =>
  queryOptions({
    queryKey: ["airwallex-form-standalone", { country, localClearingSystem }],
    queryFn: () => api.airWallex.getFormSchemaStandalone({ country, localClearingSystem }),
  });

// -------------------------------------------------------------------------------------------------

export function useCreateNewBeneficiaryMutation() {
  return useMutation({
    mutationFn: (args: CreateNewBeneficiaryRequest) => api.airWallex.createNewBeneficiary({ requestBody: args }),
  });
}

export function useCreateNewBeneficiaryStandaloneMutation() {
  return useMutation({
    mutationFn: (args: CreateNewBeneficiaryStandaloneRequest) =>
      api.airWallex.createNewBeneficiaryStandalone({ requestBody: args }),
  });
}

export function useDeleteNewBeneficiaryMutation() {
  return useMutation({
    mutationFn: (args: { supplierId: string }) => api.airWallex.deleteBeneficiary({ supplierId: args.supplierId }),
  });
}

export function usePerformPayoutMutation() {
  return useMutation({
    mutationFn: (supplierId: string) => api.airWallex.performPayout({ supplierId }),
  });
}

import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, number, object, optional, pipe, toMinValue } from "valibot";
import { supportersRoute } from "..";

export const supporterRoute = createRoute({
  path: "$supporterId",
  getParentRoute: () => supportersRoute,
  component: lazyRouteComponent(() => import("./+SupporterLayout")),
  beforeLoad: () => ({
    breadcrumb: "Edit Supporter",
  }),
});

export const supporterIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => supporterRoute,
  component: lazyRouteComponent(() => import("./+SupporterPage")),
});

export const supporterEapRoute = createRoute({
  path: "eap",
  getParentRoute: () => supporterRoute,
  component: lazyRouteComponent(() => import("./+EAPPage")),
  beforeLoad: () => ({
    breadcrumb: "EAP",
  }),
});

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const supporterWalletRoute = createRoute({
  path: "billing",
  getParentRoute: () => supporterRoute,
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+BillingPage")),
  beforeLoad: () => ({
    breadcrumb: "Billing",
  }),
});

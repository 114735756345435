import { rootRoute } from "@/pages";
import { checkAuth, checkSupplierRole } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { boolean, object, optional } from "valibot";

export const newListingRoute = createRoute({
  path: "supplier/$supplierId/listings/new",
  getParentRoute: () => rootRoute,
  beforeLoad: async ({ params }) => {
    await checkAuth();
    await checkSupplierRole(params.supplierId);
  },
  validateSearch: valibotValidator(
    object({
      eap: optional(boolean()),
    }),
  ),
  component: lazyRouteComponent(() => import("./+NewListing")),
});

import { devToolsRoute } from "@/pages/admin/devtools";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { fallback, number, object, optional, pipe, toMinValue } from "valibot";

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 10), toMinValue(10)), 10),
});

export const listingSelectorRoute = createRoute({
  path: "listing-selector",
  getParentRoute: () => devToolsRoute,
  component: lazyRouteComponent(() => import("./+ListingSelector")),
  validateSearch: searchSchema,
});

import { CSS, styled } from "@givenwell/stitches";
import { Checkbox } from "radix-ui";
import { ComponentPropsWithRef, createContext, useId } from "react";

export type FieldProps = ComponentPropsWithRef<"div"> & { css?: CSS; required?: boolean };

export function Field(props: FieldProps) {
  const baseId = useId();
  const labelId = baseId + "-label";
  const inputId = baseId + "-input";
  const descriptionId = baseId + "-description";

  return (
    <FieldContext.Provider
      value={{
        labelProps: {
          id: labelId,
          htmlFor: inputId,
          required: props.required,
        },
        inputProps: {
          id: inputId,
          "aria-labelledby": labelId,
          required: props.required,
        },
        descriptionProps: {
          id: descriptionId,
        },
      }}
    >
      <FieldRoot {...props} />
    </FieldContext.Provider>
  );
}

const FieldRoot = styled("div", {
  d: "flex",
  flexDir: "column",
  gap: 8,
  w: "100%",
});

type FieldContextValue = {
  labelProps: ComponentPropsWithRef<"label"> & { required?: boolean };
  descriptionProps: ComponentPropsWithRef<"p">;
  inputProps: ComponentPropsWithRef<"input"> &
    ComponentPropsWithRef<"textarea"> &
    ComponentPropsWithRef<"select"> &
    ComponentPropsWithRef<"button"> &
    Checkbox.CheckboxProps;
};
export const FieldContext = createContext<FieldContextValue>({
  labelProps: {},
  inputProps: {},
  descriptionProps: {},
});

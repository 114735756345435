import { billingIndexRoute, billingRoute } from "@/pages/supporter/billing";
import { billingSettingsRoute } from "@/pages/supporter/settings/billing";
import { usersSettingsIndexRoute, usersSettingsRoute } from "@/pages/supporter/settings/users";
import { walletIndexRoute, walletRoute } from "@/pages/supporter/wallet";
import { checkAuth, checkSupporterRole } from "@/utils/router";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "..";
import { allowanceSummaryRoute } from "./allowance-summary";
import { allowancesRoute } from "./allowances";
import { historyIndexRoute, historyRoute } from "./history";
import { reportsRoute } from "./reports";
import { settingsRoute } from "./settings";
import { eapSettingsRoute } from "./settings/eap";
import { supporterTeamRoute as teamRoute } from "./team";
import { userSettingsRoute } from "./user-settings";
import { allowanceTransactionsRoute } from "./wallet/allowance/[batchId]";
import { walletVersionDetailsRoute } from "./wallet/details/[version]";

export const supporterRoute = createRoute({
  path: "supporter/$supporterId",
  getParentRoute: () => rootRoute,
  loader: async ({ params }) => {
    await checkAuth();
    await checkSupporterRole(params.supporterId);
  },
  component: lazyRouteComponent(() => import("./+Layout")),
});

export const supporterIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => supporterRoute,
  // beforeLoad: ({ params: { supporterId } }) => {
  //   throw redirect({ to: "/supporter/$supporterId/allowances", params: { supporterId: supporterId } });
  // },
  component: lazyRouteComponent(() => import("./+SupporterHome")),
});

// prettier-ignore
export const supporterRouteTree = supporterRoute.addChildren({
  supporterIndexRoute,
  allowancesRoute,
  allowanceSummaryRoute,
  teamRoute,
  historyRoute: historyRoute.addChildren({
    historyIndexRoute,
  }),
  reportsRoute,
  billingRoute: billingRoute.addChildren({
    billingIndexRoute,
  }),
  settingsRoute: settingsRoute.addChildren({
    billingSettingsRoute,
    usersSettingsRoute,
    usersSettingsIndexRoute,
    eapSettingsRoute,
  }),
  walletRoute: walletRoute.addChildren({
    walletIndexRoute,
    walletVersionDetailsRoute,
    allowanceTransactionsRoute,
  }),
  userSettingsRoute,
});

import { createGlobalState, useGlobalState } from "@givenwell/hooks";

const updateAvailable = createGlobalState(false);

async function checkForUpdates() {
  const page = await fetch("/");
  const body = await page.text();
  const match = body.match(/<meta name="version" content="([^"]+)"/);
  if (!match) {
    return;
  }
  const latestVersion = match[1];
  if (!latestVersion) {
    return;
  }
  const currentVersion = import.meta.env.VITE_BUILD_TIME;

  if (latestVersion > currentVersion) {
    updateAvailable.setValue(true);
  }
}

export function periodicCheckForUpdates() {
  setInterval(() => {
    checkForUpdates().catch(console.error);
  }, 1000 * 60);
}

export function useIsUpdateAvailable() {
  return useGlobalState(updateAvailable)[0];
}

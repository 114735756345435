import { Spinner } from "@givenwell/components";
import { Outlet } from "@tanstack/react-router";
import { Suspense } from "react";

export default function RootLayout() {
  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  );
}
